import React, { useMemo, useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const colors = {
  total: am4core.color("#919191"),
  call: am4core.color("#9d53bc"),
  email: am4core.color("#54d388"),
  traffic: am4core.color("#ffbf1f"),
  social: am4core.color("#45b4d6")
};

function createChart (id) {
  const chart = am4core.create(id, am4charts.XYChart);
  chart.maskBullets = false;
  chart.cursor = new am4charts.XYCursor();
  chart.cursor.behavior = "none";
  chart.cursor.hidden = true;
  chart.dateFormatter.dateFormat = "MM/dd";

  // Create axes
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.minGridDistance = 15;
  dateAxis.endLocation = 0;
  dateAxis.renderer.labels.template.location = 0.0001;
  dateAxis.dateFormats.setKey("day", "M/d");
  dateAxis.baseInterval = {
    "timeUnit": "day",
    "count": 2
  }

  chart.yAxes.push(new am4charts.ValueAxis());

  return chart;
}

function Chart(props) {
  const {id, onInitialize, currentSeries, ...rest} = props;

  const initializeChart = useMemo(() => () => createChart(id), [id]);

  useEffect(() => {
    const chart = initializeChart();
    onInitialize(chart);
  }, [initializeChart, onInitialize]);

  return (
    <div id={id} style={{ width: "100%", height: "300px" }} {...rest}/>
  )
}

class Charts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: null,
      data: props.data,
      currentSeries: props.currentSeries
    };
    this.setChart = this.setChart.bind(this);
  }

  createChart () {
    const chart = am4core.create("chart", am4charts.XYChart);
    chart.maskBullets = false;
    chart.colors.baseColor = this.state.currentSeries.map((type) => { return (colors[type])});
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "none";
    chart.cursor.hidden = true;
    chart.dateFormatter.dateFormat = "MM/dd";

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 15;
    dateAxis.endLocation = 0;
    dateAxis.renderer.labels.template.location = 0.0001;
    dateAxis.dateFormats.setKey("day", "M/d");
    dateAxis.baseInterval = {
      "timeUnit": "day",
      "count": 2
    }

    chart.yAxes.push(new am4charts.ValueAxis());

    return chart;
  }

  createSeries(chart, type) {
    const series = chart.series.push(new am4charts.LineSeries());
    series.type = type;
    series.data = this.state.data[type];
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.tooltipText = "{dateX}: [b]{valueY}[/]";
    series.tooltip.getFillFromObject = false;
    series.strokeWidth = 2;
    series.dataItems.template.locations.dateX = 0;
    series.stroke = colors[type];
    series.tooltip.background.fill = colors[type];
    series.hidden = !this.state.currentSeries.includes(type);
    series.defaultState.transitionDuration = 500;
    series.hiddenState.transitionDuration = 500;
    series.interpolationDuration = 200;

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.fill = colors[type];
    bullet.circle.stroke = am4core.color("#fff");
    bullet.circle.strokeWidth = 2;

    return series;
  }

  showSeries (types) {
    this.state.chart.series.values.forEach(series => {
      if (types.includes(series.type)) {
        series.show();
      } else {
        series.hide();
      }
    })
  }

  componentWillUnmount () {
    if (this.state.chart) {
      setTimeout(() => this.state.chart.dispose(), 100);
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.currentSeries !== this.props.currentSeries) {
      this.setState({currentSeries: this.props.currentSeries});
      this.showSeries(this.props.currentSeries);
    }

    if (prevProps.data !== this.props.data) {
      this.setState({data: this.props.data});
    }
  }

  setChart (chart) {
    this.setState({ chart: chart });
    Object.keys(this.state.data).forEach(type => {
      this.createSeries(chart, type);
    })
  }

  render() {
    return (
      <Chart id="chart" onInitialize={this.setChart} currentSeries={this.state.currentSeries}/>
    );
  }
}

export default Charts;
