import React, { useState } from 'react';
import Charts from '../shared/Charts';
import AppLoader from '../shared/AppLoader';
import Error from '../shared/Error';
import '../assets/styles/Summary.scss';
import '../assets/styles/_install.scss';
import '../assets/styles/_reveal.scss';
import '../assets/styles/_icons.scss';
import '../assets/styles/_charts.scss';
import useFetch from "../utils/useFetch";
import SummaryToolbar, { defaultDateRange } from "./SummaryToolbar";
import { withTracking } from "../shared/Tracking";
import Todo from './Modals/Todo';

const stastics = [
  { type: 'total', text: 'VIEWS', icon: 'views', chart: 'views' },
  { type: 'call', text: 'CALLS', icon: 'call', chart: 'call' },
  { type: 'email', text: 'EMAILS COLLECTED', icon: 'contacts', chart: 'contacts' },
  { type: 'traffic', text: 'CLICKS', icon: 'clicks', chart: 'clicks' },
  { type: 'social', text: 'SOCIAL CONVERSIONS', icon: 'social', chart: 'social' }
];

const Summary = ({ site }) => {
  const [dateRange, setDateRange] = useState(defaultDateRange());
  const [currentSeries, setCurrentSeries] = useState(['total']);
  const { loading, error, status, data: { numbers, graphs } } = useFetch('summary', dateRange.from, dateRange.to);

  // if (sessionStorage.getItem('preselected_plan')) {
  //   const plan = sessionStorage.getItem('preselected_plan');
  //   const upgradeUrl = generatePath("/sites/:id/upgrade/:plan", { id: site.id, plan });
  //   return (<Redirect to={upgradeUrl}/>);
  // }

  const updateChart = (type) => () => {
    setCurrentSeries((prevValue) => {
      if (prevValue.length === 1 && prevValue.includes(type)) {
        return ['total'];
      } else if (prevValue.includes(type)) {
        return prevValue.filter((value) => value !== type);
      } else {
        return [...prevValue, type];
      }
    });
  };

  const setRange = (range) => {
    setDateRange(range);
  };

  return (
    <div>
      <SummaryToolbar site={site} rangeChanged={setRange} initialRange={1} />
      <AppLoader visible={loading}/>
      {status === 'error' && <Error error={error}/>}
      {status === 'fetched' && <React.Fragment>
        <div className="chart-wrapper tabs-data">
          {stastics.map((stat) => (
            <div className={['chart-block', stat.chart, currentSeries.includes(stat.type) && 'activated'].join(' ')}
                 data-chart="" key={stat.type} onClick={updateChart(stat.type)}>
              <i className={`icon-${stat.icon}`}></i>
              <p className="chart-overview">{numbers[`${stat.type}_humanized`]}
                <small>{stat.text}</small>
              </p>
            </div>
          ))}
        </div>
        <Charts data={graphs} currentSeries={currentSeries}/>
      </React.Fragment>}
      <Todo />
    </div>
  );
}

export default withTracking(Summary);
