import React from 'react';
import SidebarItem from './Sidebar/SidebarItem';
import HelpDialog from '../shared/HelpDialog';
import Button from '../shared/Button';
import '../assets/styles/Sidebar.scss';
import '../assets/styles/_icons.scss';
import { editorURL } from '../utils/editor';
import NewSiteDialog from '../shared/NewSiteDialog';
import { withTracking } from '../shared/Tracking';

function PartnerSidebar(props) {
  const site = props.site;

  return (
    <div className="sidebar">
      <div className="sidebar-nav-link no-hover">
        {site && (
          <React.Fragment>
            <ul>
              <SidebarItem
                name="Summary"
                path={`/sites/${site.id}/summary`}
                className="icon-summary"
              />
              {site.partner === 'vmarketplace' && (
                <SidebarItem
                  name="Install"
                  path={`/sites/${site.id}/install`}
                  className="icon-summary"
                />
              )}
              <SidebarItem
                name="Popups"
                path={`/sites/${site.id}/manage`}
                className="icon-manage"
              />
              <SidebarItem
                name="Targeting"
                path={`/sites/${site.id}/rules`}
                className=""
              />
              <SidebarItem
                name="Contacts"
                path={`/sites/${site.id}/contacts`}
                className="icon-contacts"
              />
              {(site.partner === 'dsitebuilder' ||
                site.partner === 'shopify' ||
                site.partner === 'bigcommerce') && (
                <SidebarItem
                  name="Settings"
                  path={`/sites/${site.id}/settings/site`}
                  className="icon-settings"
                />
              )}
              <HelpDialog isOpen={false} showIcon={true} />
            </ul>

            <div className="new-popup-wrapper">
              <a href={editorURL(site, '/new/interstitial')}>
                <Button color="secondary" label="Create New Popup" />
              </a>
            </div>
          </React.Fragment>
        )}
        {!site && (
          <div className="new-popup-wrapper">
            <NewSiteDialog isOpen={true} />
          </div>
        )}
      </div>
      <div className="sidebar-content">{props.children}</div>
    </div>
  );
}

export default withTracking(PartnerSidebar);
