import React, { useMemo, useState } from 'react';
import '../assets/styles/Header.scss';
import logo from '../assets/images/hellobar-logo.png';
import caret_down from '../assets/images/caret_down_gray.svg';
import IconLogout from '../assets/images/icon-logout.svg';
import BellIcon from '../assets/images/bell-icon';
import Button from '../shared/Button';
import Avatar from '../shared/Avatar';
import SiteLink from '../shared/SiteLink';
import NewSiteDialog from '../shared/NewSiteDialog';
import { UserContext } from '../utils/contexts';
import { auth } from '../utils/auth';
import { useHistory } from 'react-router-dom';
import { Card } from '@mui/material';
import Search from '../shared/Search';
import moment from 'moment';

function canDisplayLabel(site) {
  if (
    site.plan.free &&
    site.pre_selected_plan &&
    site.pre_selected_plan.includes('financial-')
  ) {
    return false;
  } else {
    return true;
  }
}

const lastPartInPath = (pathname) => {
  const paths = pathname.split('/');
  return paths[paths.length - 1];
};

export function Site({ site, currentSiteId }) {
  const history = useHistory();
  const isManage = lastPartInPath(history.location.pathname) === 'manage';
  const className = site.id === currentSiteId ? 'active' : '';

  const summaryPage = (
    <SiteLink site={site} className={className} to="/summary">
      {site.url}
    </SiteLink>
  );
  const installPage = (
    <SiteLink site={site} className={className} to="/install">
      {site.url}
    </SiteLink>
  );
  const managePage = (
    <SiteLink site={site} className={className} to="/manage">
      {site.url}
    </SiteLink>
  );

  return (
    <li className="padded" title={site.url}>
      {site.plan && canDisplayLabel(site) && (
        <div className={`pill ${site.plan.label}`}>{site.plan.label}</div>
      )}
      {isManage ? managePage : site.installed ? summaryPage : installPage}
    </li>
  );
}

function SiteSelector({ sites, currentSite }) {
  return (
    <Card className="dropdown-wrapper dropdown-box">
      <div className="dropdown_items">
        <Search sites={sites} isHeaderSearch={true} />
      </div>
      <div className="create-new-site">
        <NewSiteDialog site={currentSite} />
      </div>
    </Card>
  );
}

const TrialBanner = (props) => {
  const { freeTrialEndsAt, plan } = props;
  const upgradeUrl = () => `/upgrade/${plan.stripe_id}`;
  return (
    <div className="expired-trial-banner">
      <div className="banner">
        <div className="label">
          {moment(freeTrialEndsAt).diff(moment(), 'days') >= 0
            ? moment(freeTrialEndsAt).diff(moment(), 'days') +
              ' days remaining in free trial.'
            : 'Your free trial has expired.'}
        </div>

        <SiteLink to={upgradeUrl()}>
          <div className="target">Upgrade Now</div>
        </SiteLink>
      </div>
    </div>
  );
};

function Header({ site, isDisable }) {
  const displayUpgrade = (plan) =>
    ['GRW', 'PRM', 'FREE', 'FFR', 'STR'].includes(plan.label) &&
    !site.pre_selected_plan.includes('financial-') &&
    site.subscription_status !== 'trialing';

  const onLogout = async (event) => {
    event.preventDefault();
    await auth.logout();
  };

  const [open, setOpen] = useState(false);
  const toggleSiteSelector = () => {
    setOpen(!open);
  };

  return (
    <UserContext.Consumer>
      {(user) => (
        <div className={`header ${isDisable ? 'disabled' : ''}`}>
          <div className="header-logo">
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
          </div>
          {site && (
            <div className="header-site-wrapper">
              <div
                className="site-flex"
                onMouseEnter={() => toggleSiteSelector()}
                onMouseLeave={() => toggleSiteSelector()}
              >
                <div className="site-label">
                  {site.plan && canDisplayLabel(site) && (
                    <span className={`pill plan ${site.plan.label}`}>
                      {site.plan.name} plan
                    </span>
                  )}
                </div>
                <div className="site-name" title={site.url}>
                  {site.url}
                </div>
                <div className="caret">
                  <img src={caret_down} alt="Caret down" />
                </div>
                {open && <SiteSelector sites={user.sites} currentSite={site} />}
              </div>
            </div>
          )}

          <div className="header-user-wrapper">
            <div className="warning-banners">
              {site &&
                !site.purchased_onboarding &&
                !site.installed &&
                window.location.pathname !== `/sites/${site.id}/install` && (
                  <div className="installation-warning">
                    <div className="banner">
                      <div className="label">
                        {/* <i className="icon-alert"></i> */}
                        Your popups won't be visible until HelloBar is
                        installed.
                      </div>

                      <SiteLink to="/install">
                        <div className="target">Install Now</div>
                      </SiteLink>
                    </div>
                  </div>
                )}
              {site?.subscription_status === 'trialing' &&
                site.free_trial_ends_at && (
                  <TrialBanner
                    plan={site.plan}
                    freeTrialEndsAt={site.free_trial_ends_at}
                  />
                )}
            </div>
            <div className="header-stats">
              {site && displayUpgrade(site.plan) && (
                <div className="ctas">
                  <span>
                    {site.number_of_views}{' '}
                    {site.plan.label === 'FFR'
                      ? 'total views'
                      : 'views this month'}
                    . Need more?{' '}
                  </span>
                  {site.current_role !== 'viewer' ? (
                    <>
                      {site.plan.label === 'STR' && (
                        <SiteLink to="/upgrade/growth_1-monthly">
                          <Button color="white">Upgrade to Growth</Button>
                        </SiteLink>
                      )}
                      {site.plan.label === 'GRW' && (
                        <SiteLink to="/upgrade/premium_1-monthly">
                          <Button color="white">Upgrade to Premium</Button>
                        </SiteLink>
                      )}
                      {site.plan.label === 'PRM' && (
                        <SiteLink to="/upgrade/elite-monthly">
                          <Button color="white">Upgrade to Elite</Button>
                        </SiteLink>
                      )}
                      {site.plan.label === 'FFR' && (
                        <SiteLink to="/upgrade/growth_1-monthly">
                          <Button color="white">Upgrade to Growth</Button>
                        </SiteLink>
                      )}
                      {site.plan.label === 'FREE' && (
                        <SiteLink to="/upgrade">
                          <Button color="white">Upgrade Now</Button>
                        </SiteLink>
                      )}
                    </>
                  ) : (
                    <Button color="white" disabled>
                      Upgrade Now
                    </Button>
                  )}
                </div>
              )}
            </div>

            <div className="hellobar-changelogs">
              <BellIcon />
            </div>
            <a href="/logout" className="icon-logout" onClick={onLogout}>
              <img src={IconLogout} />
            </a>
            {/* <div className="dropdown-wrapper">
              <Avatar user={user} />
              <span className="user-name">{user.name || user.email}</span>
              <span className="caret">
                <img src={caret_down} alt="Caret down" />
              </span>
              <div className="dropdown-block">
                {site && <h6>{site.plan.name} Plan</h6>}
                <SiteLink to="/settings/site">Settings</SiteLink>
                <SiteLink to="/install">Installation Instructions</SiteLink>
                {site && site.current_role !== 'viewer' && (
                  <SiteLink to="/settings/billing">Billing</SiteLink>
                )}

                {site &&
                  site.current_role !== 'viewer' &&
                  (site.plan.free ||
                    site.plan.free_forever ||
                    !site.plan.stripe_id.includes('financial-')) && (
                    <SiteLink to="/upgrade">Upgrade Plan</SiteLink>
                  )}
                <a href="/logout" onClick={onLogout}>
                  Sign Out
                </a>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
}

export default Header;
