import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Tabs from '../shared/Tabs';
import ActiveRules from '../components/Manage/ActiveRules';
import PausedRules from '../components/Manage/PausedRules';
import AllRules from '../components/Manage/AllRules';
import AppLoader from '../shared/AppLoader';
import useFetch from '../utils/useFetch';
import { SitePath } from '../shared/SiteLink';
import useAPI from '../utils/api';
import { withTracking } from '../shared/Tracking';
import { SearchContext, ManageContext } from '../utils/contexts';
import { useSnackbar } from 'notistack';
import Todo from './Modals/Todo';

function Manage({ site }) {
  const [update, setUpdating] = useState(false);
  const [resetCache, setResetCache] = useState(false);
  const [query, setQuery] = useState('');
  const { loading, status, data } = useFetch('rules', update);
  const { enqueueSnackbar } = useSnackbar();

  const rules = data;
  const API = useAPI();
  const history = useHistory();

  const togglePopup = async (popup) => {
    const [ok, response] = await API.popupTogglePause(popup.id);
    if (ok && response) {
      enqueueSnackbar('popup updated successfully.', { variant: 'success' });
    } else {
      enqueueSnackbar(response?.message || response.error, {
        variant: 'error',
      });
    }
    setUpdating(!update);
  };

  const deletePopup = async (popup) => {
    await API.popupDelete(popup.id);
    setUpdating(!update);
  };

  const copyPopup = async (popup, site) => {
    await API.popupCopy(popup.id, site.id);
    history.push(`/sites/${site.id}/manage/paused`);
    setUpdating(!update);
  };

  const [activeTab, pausedTab, deactivatedTab, allTab] = [
    SitePath('/manage'),
    SitePath('/manage/paused'),
    SitePath('/manage/deactivated'),
    SitePath('/manage/all'),
  ];

  const tabs = (rules) => {
    const tabs = [
      {
        name: 'Active',
        rules: rules,
        path: activeTab,
        render: () => (
          <ActiveRules
            togglePopup={togglePopup}
            deletePopup={deletePopup}
            copyPopup={copyPopup}
            rules={rules}
            name="active"
            query={query}
            resetCache={resetCache}
          />
        ),
      },
      {
        name: 'Paused',
        path: pausedTab,
        render: () => (
          <PausedRules
            togglePopup={togglePopup}
            deletePopup={deletePopup}
            copyPopup={copyPopup}
            rules={rules}
            name="paused"
            query={query}
            resetCache={resetCache}
          />
        ),
      },
      {
        name: 'All',
        path: allTab,
        render: () => (
          <AllRules
            togglePopup={togglePopup}
            deletePopup={deletePopup}
            copyPopup={copyPopup}
            rules={rules}
            name="all"
            query={query}
            resetCache={resetCache}
          />
        ),
      },
    ];

    if (site.deactivated) {
      tabs.push({
        name: 'Deactivated',
        path: deactivatedTab,
        render: () => (
          <ActiveRules
            togglePopup={togglePopup}
            deletePopup={deletePopup}
            copyPopup={copyPopup}
            rules={rules}
            scope="deactivated"
            name="deactivated"
            query={query}
            resetCache={resetCache}
          />
        ),
      });
    }
    return tabs;
  };
  return (
    <div>
      <AppLoader visible={loading} />
      <SearchContext.Provider value={{ query, rules }}>
        <ManageContext.Provider
          value={{ rules, updateRules: () => setUpdating(!update), setResetCache: (value) => setResetCache(value) }}
        >
          {status === 'fetched' && (
            <Tabs
              tabs={tabs(rules)}
              showSearch={true}
              changeQuery={setQuery}
              placeholder={'Search Popups or Rules...'}
              type='manage'
            />
          )}
        </ManageContext.Provider>
      </SearchContext.Provider>
      <Todo />
    </div>
  );
}

export default withTracking(Manage);
